import React from "react";
import { FaWhatsapp, FaEnvelopeOpen } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import Title from "@components/common/Title";
import "../../styles/ContactUs.css";

const ContactUs = props => {
    return (
        <div className={`contactus ${props.className}`}>
            <div className="container lg:px-0 py-20">
                <Title className="text-center">¿Hablamos?</Title>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-10">
                    {/** WhatsApp */}
                    <div className="content">
                        <div className="mx-auto my-auto">
                            <a
                                href="https://wa.me/34644507478"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-full"
                            >
                                <FaWhatsapp className="icon" />
                                <h3 className="h3">WhatsApp</h3>
                                <h4 className="h4">(+34) 644 507 478</h4>
                            </a>
                        </div>
                    </div>

                    {/** Correo */}
                    <div className="content">
                        <div className="mx-auto my-auto">
                            <a
                                href="mailto:info@iopos.academy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-full"
                            >
                                <FaEnvelopeOpen className="icon" />
                                <h3 className="h3">Correo electrónico</h3>
                                <h4 className="h4">info@iopos.academy</h4>
                            </a>
                        </div>
                    </div>

                    {/** WhatsApp */}
                    <div className="content">
                        <div className="mx-auto my-auto">
                            <a
                                href="https://wa.me/34644507478"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-full"
                            >
                                <BsChatDots className="icon" />
                                <h3 className="h3">Chat</h3>
                                <h4 className="h4">¡Hablemos por WhatsApp!</h4>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContactUs;
