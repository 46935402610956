import React from "react";
import loadable from "@loadable/component";
import useNearScreen from "@components/hooks/useNearScreen";

const MapView = loadable(() => import("./MapView"));

export default function LazyMapView() {
    const { isNearScreen, fromRef } = useNearScreen();

    return (
        <>
            <section ref={fromRef}>{isNearScreen ? <MapView /> : null}</section>
        </>
    );
}
