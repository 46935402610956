import React from "react";
import loadable from "@loadable/component";
import useNearScreen from "@components/hooks/useNearScreen";

const ContactForm = loadable(() => import("./ContactForm"));

const LazyContactForm = () => {
    const { isNearScreen, fromRef } = useNearScreen();

    return (
        <>
            <section ref={fromRef}>{isNearScreen ? <ContactForm /> : null}</section>
        </>
    );
};

export default LazyContactForm;

/**
 *
 * .grecaptcha-badge {
    visibility: hidden;
}
 *
 */
